import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Header from './Header'
import posthog from 'posthog-js'
import { Helmet } from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import { PLATFORMS } from './Constants'
import styles from './Landing.module.css'
import ProgressBar from 'react-progress-bar-plus'
import 'react-progress-bar-plus/lib/progress-bar.css'

const Info = () => (
  <div>
    <div className={ styles.infoWrapper }>
      <div>
        <h4>Chart the Climb</h4>
        <span>Create leaderboards with your friends <br /> to visualize the ranked season.</span>
        <div className={ styles.infoImage }><img src='/phone.png' alt='Chart screenshot from a phone.' /></div>
      </div>

      <div>
        <h4>Track Your Daily Progress</h4>
        <span>Keep tabs on today&#39;s LP changes and <br />win&#x2011;loss record at a glance.</span>
        <div className={ styles.infoImage }><img src='/table.png' alt='Example leaderboard table.' /></div>
      </div>
    </div>
  </div>
)


class StarredLeaderboards extends Component {
  state = {
    starred: JSON.parse(localStorage.getItem('starred') || '[]'),
  }

  removeStarred = (idx) => {
    const { starred } = this.state

    const removed = starred.splice(idx, 1)
    localStorage.setItem('starred', JSON.stringify(starred))
    this.setState({ starred })

    if (removed.length > 0 && removed[0].slug) {
      posthog.capture('unstarred_leaderboard', {
        leaderboard: removed[0].slug,
      })
    }
  }

  render() {
    const { starred } = this.state

    var isExample = false
    var header = 'Starred Leaderboards'
    var data = starred

    // Hardcode example leaderboards if none are starred.
    if (starred.length === 0) {
      isExample = true
      header = 'Example Leaderboards'
      data = [
        {
          leaderboardName: 'Los Ratones',
          region: 'euw1',
          slug: 'los-ratones',
          summoners: [
            'LR Velja#2203',
            'LR Nemesis#LRAT',
            'LR Crownie#LRAT',
            'Thebausffs#EUW',
            'Shaun Murphy#1996',
            'e7c3c0fd-cee3-4399-877b-baf89c9f6f46#EUW',
            'SOVEREIGN33#QWE',
          ],
        },
        {
          leaderboardName: 'T1',
          region: 'kr',
          slug: 't1',
          summoners: [
            '어리고싶다#KR1',
            'Oner#KR222',
            '역천괴#Ker10',
            'T1 Gumayusi#KR1',
            'Hide on bush#KR1',
          ],
        },
        // {
        //   leaderboardName: 'げまげま',
        //   region: 'jp1',
        //   slug: 'gemaxgema',
        //   summoners: [
        //     'Ninja of Ninjas#JP1',
        //     'Jasper7se#JSP',
        //     'スタンミジャパン#JP1',
        //     'syaruru#0323',
        //     'らいじん#JP1',
        //   ],
        // },
      ]
    } else {
      data = starred
    }

    const items = data.map((lb, i) => {
      const title = `zeal.gg/${lb.slug}`
      const linkHref = `/${lb.slug}`
      const linkText = `${lb.leaderboardName || (lb.summoners || []).join(', ')}`
      const region = `[${PLATFORMS[lb.region]}] `
      var unstar = null

      if (!isExample) {
        unstar = <FontAwesomeIcon
          icon={ faTimes }
          size="sm"
          title="Unstar this leaderboard"
          className={ styles.remove }
          onClick={ () => this.removeStarred(i) } />
      }

      return (
        <li title="" key={ i }>
          { unstar }
          <span>{ region }</span>
          <a href={ linkHref } title={ title }>{ linkText }</a>
        </li>
      )
    })

    return (
      <div className={ styles.starred }>
        <strong>{ header }</strong>
        <ul>{ items }</ul>
      </div>
    )
  }
}

class Landing extends Component {
  constructor(props) {
    super(props)
    this.state = {
      percent: -1,
      region: localStorage.getItem('region') || 'na',
      redirect: null,
      disabled: false,
      message: null,
      timeout: null,
    }
  }

  setPercent = (percent) => {
    this.setState({ percent })
  }

  startProgress = () => {
    this.setState({ percent: 0 })
  }

  showError = (message) => {
    const { percent, timeout } = this.state

    if (percent !== -1) {
      this.setPercent(100)
    }

    this.setState({ message })
    this.setState({ disabled: false })

    if (timeout != null) {
      clearTimeout(timeout)
    }

    const newTimeout = setTimeout(() => {
      this.setState({ message: null})
    }, 5000)

    this.setState({ timeout: newTimeout })
  }

  pollRedirect = (url, tries) => {
    if (tries <= 0) {
      this.showError('Failed to create leaderboard.')
      return
    }

    fetch(url)
      .then(res => {
        res.json().then(resp => {
          if (res.status === 200 && resp.location != null) {
            this.setPercent(100)

            // Delay redirect to allow progress bar to complete animation
            setTimeout(() => {
              this.setState({ redirect: resp.location })
            }, 250)

            return
          } else if (res.status >= 400) {
            this.showError(resp)
          } else {
            // Check again in 2.5 seconds
            setTimeout(() => this.pollRedirect(url, tries - 1), 2500)
          }
        })
      }).catch(err => {
        this.showError('Failed to create leaderboard.')
      })
  }

  handleSubmit = (evt) => {
    const { region, disabled } = this.state
    evt.preventDefault()

    if (disabled) {
      return
    }

    this.setState({ disabled: true })

    const input = evt.target.summoners
    const summoners = input.value
    const summonersList = summoners.split(/[,、]/)

    if (!summoners) {
      this.showError('Please enter a comma-separated list of Riot IDs.')
      return
    }

    // Ensure each Riot ID provided has a valid tagline.
    for (const id of summonersList) {
      if (!id.match(/^[^#]+#[^\s^#]{1,5}$/)) {
        this.showError('Riot IDs must end with a valid tagline (eg. #NA1).')
        return
      }
    }

    if (summonersList.length > 12) {
      this.showError('Please enter 12 or fewer Riot IDs.')
      return
    }

    let formData = new FormData()
    formData.append('region', region)
    formData.append('summoners', summoners)

    const options = {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
      },
    }

    fetch('/api/leaderboard', options)
      .then(res => {
        if (res.status === 202 && res.headers.get('Location') != null) {
          const statusUrl = res.headers.get('Location')
          setTimeout(() => this.pollRedirect(statusUrl, 6), 1000)
          this.startProgress()
        } else {
          this.showError('Failed to queue leaderboard creation. Please try again later.')
        }
      }).catch(err => {
        this.showError('Failed to queue leaderboard creation. Please try again later.')
      })
  }

  changeRegion = (evt) => {
    const region = evt.target.value
    this.setState({ region })
    localStorage.setItem('region', region)
  }

  render() {
    const { percent, redirect, disabled, region, message } = this.state

    if (redirect != null) {
      return <Redirect push to={ redirect } />
    }

    return (
      <div>
        <Helmet><title>zeal.gg &mdash; Chart the Solo Queue Climb</title></Helmet>

        <div className={ styles.header }>
          <Header />
        </div>

        <ProgressBar
          percent={ percent }
          autoIncrement
          intervalTime={ 100 }
          spinner={ false }
          className={ styles.progress }
        />

        <div className={ styles.landing }>
          <div className={ styles.logo }>
            <img src="/zeal.svg" alt="zeal.gg Logo" />
            <div className={ styles.title }>zeal.gg</div>
          </div>

          <form className={ message == null ? styles.summonerSelect : styles.summonerSelectMessage } onSubmit={ this.handleSubmit }>
            <input type="text" name="summoners" disabled={ disabled } tabIndex="1" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" placeholder="Hide on bush#KR1, Deft#8366, &hellip;" />
            <button disabled={ disabled } tabIndex="-1">&rsaquo;</button>

            <span className={ styles.message }>{ message }</span>
          </form>

          <div className={ styles.regionSelect }>
            <span>Region:</span>
            <select tabIndex="2" defaultValue={ region } onChange={ this.changeRegion }>
              <option value="br">BR</option>
              <option value="eune">EUNE</option>
              <option value="euw">EUW</option>
              <option value="jp">JP</option>
              <option value="kr">KR</option>
              <option value="lan">LAN</option>
              <option value="las">LAS</option>
              <option value="na">NA</option>
              <option value="oce">OCE</option>
              <option value="tr">TR</option>
              <option value="tw">TW</option>
              <option value="ru">RU</option>
              <option value="sea">SEA</option>
              <option value="vn">VN</option>
            </select>
          </div>

          <StarredLeaderboards />
          <Info />
        </div>
      </div>
    )
  }
}

export default Landing
